.contact-form {
    position: relative;
    display: flex;
    z-index: 1;
    flex-flow: column wrap;
    align-items: center;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0.02;
        background-image: url('./images/compass.svg?url');
        background-position: center;
        background-repeat: no-repeat;
    }
}

.input-message {
    margin: 10px 0;
}
