@use '@base-styles-mixins/typography' as t;
@use '@base-styles-variables/colors' as c;

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    opacity: 0.7;
    background-color: c.$beige-light;
}

.container {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1001;
    transform: translate(-50%, -50%);
}

.loader {
    position: relative;
    width: 170px;
    height: 120px;

    .gear-s,
    .gear-m,
    .gear-l {
        fill: c.$brown-dark;
    }

    .gear-s {
        position: absolute;
        top: 20px;
        left: 0;
        width: 50px;
        animation: rotation 2.5s infinite linear reverse;
        aspect-ratio: 1;
    }

    .gear-m {
        position: absolute;
        top: 49px;
        left: 29px;
        width: 68px;
        animation: rotation 3.75s infinite linear;
        aspect-ratio: 1;
    }

    .gear-l {
        position: absolute;
        top: 0;
        left: 80px;
        width: 87px;
        animation: rotation 5s infinite linear reverse;
        aspect-ratio: 1;
    }

    @media (prefers-reduced-motion) {
        .gear-s,
        .gear-m,
        .gear-l {
            animation: none;
        }
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(359deg);
        }
    }
}

.message {
    @include t.t2;

    display: inline-block;
    width: 100%;
    margin-top: 5px;
    text-align: center;
}
