@use '@base-styles-variables/colors' as c;
@use '@base-styles-variables/application' as app;
@use '@base-styles-mixins/common' as cmn;
@use '@base-styles-mixins/bp';

$btn-image-width: 38px;
$btn-image-width-mobile: 28px;
$btn-right-padding: 10px;

.scroll-top-btn {
    @include cmn.button-reset;

    position: fixed;
    bottom: 45px;
    border: 1px solid transparent;
    opacity: 0;
    visibility: hidden;
    transform: translateX(calc(app.$app-max-width - $btn-right-padding - $btn-image-width));
    transition: visibility 0.3s, opacity 0.3s linear;

    @media screen and (max-width: #{app.$app-max-width}) {
        right: $btn-right-padding;
        transform: none;
    }

    &.m-visible {
        opacity: 1;
        visibility: visible;
    }

    &:hover,
    &:focus-visible {
        .scroll-top-btn-img {
            fill: c.$brown;
        }
    }

    &:active {
        .scroll-top-btn-img {
            fill: c.$red;
        }
    }
}

.scroll-top-btn-img {
    display: inline-block;
    width: $btn-image-width;
    aspect-ratio: 38/63;
    fill: c.$brown-dark;

    @include bp.lw(m) {
        width: $btn-image-width-mobile;
    }
}
