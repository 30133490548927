@use '@base-styles-variables/colors' as c;
@use '@base-styles-mixins/typography' as t;
@use '@base-styles-mixins/common' as cmn;

.accessibility-helper {
    @include cmn.popup;

    position: fixed;
    top: 0;
    left: 50%;
    z-index: 100;
    padding: 10px;
    border-top: 0;
    transform: translate(-50%, -120%);
    transition: transform 0.25s;

    &:focus-within {
        transform: translate(-50%, 0%);
    }

    @media (prefers-reduced-motion) {
        transition: none;
    }
}

.skip-links-list,
.a11y-statement-button {
    @include t.b2;
}

.skip-links-list {
    margin-bottom: 5px;
    padding-bottom: 10px;
    border-bottom: 2px solid c.$brown-dark;
}

.skip-links-item {
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }
}

.a11y-statement-btn {
    @include cmn.button-reset;
    @include cmn.link;
}
