@use './variables.scss' as v;
@use '@base-styles-variables/colors' as c;
@use '@base-styles-mixins/bp';
@use '@base-styles-mixins/common' as cmn;
@use '@base-styles-mixins/typography' as t;

.popup-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    background-color: oklch(0% 0 0 / 30%);
}

.popup {
    @include cmn.popup;
    @include t.b2;

    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    min-height: 200px;
    max-height: calc(100% - 60px); // 60px = 30px + 30px margin safe space
    margin: 30px;
    background-color: c.$beige-light;

    @include bp.lw(m) {
        width: 100%;
        height: 100%;
        max-height: 100%;
        margin: 0;
    }
}

.popup-close-btn {
    @include cmn.button-reset;

    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: v.$popup-close-btn-dimension;
    height: v.$popup-close-btn-dimension;
    margin: v.$popup-close-btn-margin;

    &:hover,
    &:focus-visible {
        .popup-close-btn-icn {
            fill: c.$brown;
        }
    }

    &:active {
        .popup-close-btn-icn {
            fill: c.$red;
        }
    }
}
