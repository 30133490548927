@use '@base-styles-variables/colors' as c;
@use '@base-styles-variables/application' as app;

// Gecko (Firefox)
* {
    scrollbar-color: c.$brown-dark transparent;
    scrollbar-width: thin;
}

// Webkit (Chrome, Edge)
::-webkit-scrollbar {
    width: app.$scrollbar-width;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 1px solid c.$brown-dark;
    background-color: c.$beige;

    &:hover {
        background-color: c.$beige-light;
    }
}

::-webkit-scrollbar-button {
    display: none;
}
