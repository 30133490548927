// Application main colors definition

$beige-light: oklch(96% 0.024 100);
$beige: oklch(93% 0.04 100);
$brown: oklch(55% 0.035 94);
$brown-dark: oklch(40% 0.035 94);
$red: oklch(50% 0.25 30);
$yellow: oklch(97% 0.08 110);
$white: oklch(100% 0 0);
$grey: oklch(70% 0 0);
$black: oklch(0% 0 0);
