@use '@base-styles-variables/colors' as c;
@use '@base-styles-variables/fonts' as f;
@use '@base-styles-mixins/common' as cmn;
@use '@base-styles-mixins/animations' as a;

.button {
    @include cmn.button-reset;

    position: relative;
    width: fit-content;
    margin: 10px; // To reserve outside space for the gear
    padding: 10px 18px 8px;
    background-color: c.$brown-dark;
    color: c.$white;
    font-family: f.$ff-headings;
    font-size: 16px;
    line-height: 16px;

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }

    &:not([disabled]) {
        &:hover,
        &:focus-visible {
            background-color: c.$brown;

            .gear-img {
                @include a.gear-clockwise-rotate;

                fill: c.$brown;

                @media (prefers-reduced-motion) {
                    animation: none;
                }
            }
        }

        &:active {
            background-color: c.$red;

            .gear-img {
                fill: c.$red;
            }
        }
    }
}

.gear-img {
    position: absolute;
    top: -9px;
    right: -9px;
    display: block;
    width: 24px;
    height: 24px;
    fill: c.$brown-dark;
}
