@use '@base-styles-variables/colors' as c;
@use '@base-styles-mixins/bp';
@use '@base-styles-mixins/animations' as a;
@use './variables.scss' as v;

.header {
    position: sticky;
    top: -(v.$header-margin);
    display: flex;
    z-index: 100;
    flex-flow: column nowrap;

    @include bp.lw(m) {
        top: -(v.$header-margin-mobile);
    }
}

.header-inner {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: v.$header-height;
    margin: v.$header-margin 0;
    border-top: 1px solid c.$brown-dark;
    border-bottom: 1px solid c.$brown-dark;
    background-color: c.$beige;

    @include bp.lw(m) {
        height: v.$header-height-mobile;
        margin: v.$header-margin-mobile 0;
    }
}

.header-meta {
    position: absolute;
    top: 50%;
    right: 8%;
    display: flex;
    flex-flow: row nowrap;
    transform: translateY(-50%);

    @include bp.lw(m) {
        right: 10%;
    }

    & > * {
        margin-left: 30px;

        @include bp.lw(m) {
            margin-left: 12px;
        }
    }
}

.gear-wrapper {
    position: absolute;
    top: 0;
    overflow: hidden;
    height: 100%;
    aspect-ratio: 0.5;

    .gear {
        position: absolute;
        height: 70%;
        fill: c.$brown-dark;
    }

    &.m-left {
        left: 0;

        .gear {
            top: 15%;
            left: -70%;
        }
    }

    &.m-right {
        right: 0;

        .gear {
            top: 15%;
            right: -70%;
        }
    }

    &.m-animate {
        &.m-left {
            .gear {
                @include a.gear-clockwise-rotate;
            }
        }

        &.m-right {
            .gear {
                @include a.gear-counterclockwise-rotate;
            }
        }

        @media (prefers-reduced-motion) {
            &.m-left,
            &.m-right {
                .gear {
                    animation-iteration-count: 1;
                }
            }
        }
    }
}
