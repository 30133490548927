/* RESET + NORMALIZE STYLES */

/* HTML element basic styles reset for convenient override */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
audio,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
mark,
menu,
meter,
nav,
output,
progress,
section,
summary,
time,
video {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    vertical-align: baseline;
}

/* Interactive elements default cursor */
a,
label,
button,
select,
summary,
[type=radio],
[type=submit],
[type=checkbox] {
    cursor: pointer;
}

article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section,
blockquote {
    display: block;
}

/* Remove list styles (bullets/numbers) */
menu,
ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: '';
    content: none;
}

ins {
    text-decoration: underline;
}

del {
    text-decoration: line-through;
}

mark {
    background: none;
}

/* Removes spacing between cells in tables */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* For images to not be able to exceed their container */
img {
    display: block;
    max-width: 100%;
}

input,
select {
    vertical-align: middle;
}

input[type='submit'],
input[type='button'],
button,
textarea,
input,
input[type='text'],
input[type='email'] {
    border-radius: 0;
    appearance: none;
}

/* Preferred box-sizing value */
*::before,
*::after {
    box-sizing: border-box;
}

* {
    box-sizing: border-box;
}

/* Reset default text opacity of input placeholder */
::placeholder {
    color: unset;
}
