@use '@styles/main';
@use '@base-styles-mixins/typography' as t;
@use '@base-styles-mixins/bp';
@use '@base-styles-variables/colors' as c;
@use '@base-styles-variables/application' as app;

.app-container {
    @include t.b2;

    position: relative;
    max-width: app.$app-max-width;
    min-height: 100vh;
    margin: 0 auto;
    padding: 0;
    box-sizing: content-box;
    border-right: 1px solid c.$brown-dark;
    border-left: 1px solid c.$brown-dark;
    background-image: url('@assets/images/bg-image.webp');

    @include bp.lw-app-max-width {
        border: 0;
    }

    main {
        /**
        * To follow one of Core Web vital metric - Cumulative Layout Shift, "min-height" should be
        * specified in the way when <footer> will be out of viewport on initial load thus
        * not be visually "shifted" for the User once lazy-loaded content of <main> takes in place.
        * More details: https://shorturl.at/koT29
        */
        min-height: 700px;
    }
}
