@use './reset-normalize';
@use './fonts-declarations';
@use './scrollbar';
@use '@base-styles-variables/colors' as c;

body {
    // Replacing scroll from body element to achieve fixed address bar.
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    min-height: 100%;
    background-color: c.$beige-light;

    // Remove app scroll when popup opened
    &:has(:global(.popup)) {
        overflow: hidden;
    }
}

// Visually hide (A11y purposes)
/* stylelint-disable declaration-no-important -- Essential styles should NOT be overritten */
:global(.visuallyhidden) {
    position: absolute !important;
    overflow: hidden !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(50%);
}

// App Global elements styling
// Outline padding (A11y purposes)
* {
    outline-offset: 3px;
}
