@use '@base-styles-variables/colors' as c;
@use '@base-styles-variables/fonts' as f;
@use '@base-styles-mixins/typography' as t;

.input-container {
    width: 100%;

    &.m-with-bottom-margin {
        margin-bottom: 7px;
    }
}

.label {
    font-size: 14px;
    font-weight: f.$fw-medium;
    line-height: 22px;
}

.input {
    @include t.b2;

    width: 100%;
    height: 30px;
    border: 0;
    border-bottom: 1px solid c.$brown-dark;
    background: 0;

    &.m-textarea {
        min-width: 100px;
        max-width: 100%;
        height: auto;
        min-height: 60px;
        border: 1px solid c.$brown-dark;
    }

    &::placeholder {
        color: c.$grey;
    }

    &.m-touched:invalid {
        border-width: 2px;
        border-color: c.$red;

        &::placeholder {
            color: c.$red;
        }
    }
}
