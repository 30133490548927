.logo {
    position: absolute;
    left: 50%;
    display: flex;
    z-index: 1;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    transform: translateX(-50%);
    transform-origin: center;

    .logo-image {
        height: 100%;
        transform: scale(1.8);
        transition: transform 0.15s linear;
        aspect-ratio: 220/180;

        @media (prefers-reduced-motion) {
            transform: scale(0.98);
            transition: none;
        }
    }

    &.m-compact {
        .logo-image {
            transform: scale(0.98);
        }
    }
}
