@use '@base-styles-variables/colors' as c;
@use '@base-styles-mixins/common' as cmn;
@use '@base-styles-mixins/bp';

.language-switcher {
    @include cmn.button-reset;

    &:active {
        .language-switcher-img {
            border: 1px solid c.$brown-dark;
        }
    }
}

.language-switcher-img {
    width: 32px;
    aspect-ratio: 32/24;

    @include bp.lw(m) {
        width: 25px;
    }
}
