// Application typography definition
@use '@base-styles-variables/fonts';
@use '@base-styles-variables/colors';
@use '@base-styles-mixins/bp';

// Note: word-spacing and letter-spacing adjusted to follow as possible (still limited) WCAG 2.1 (AA)

// Headings
@mixin h1() {
    word-spacing: 0.11em;
    letter-spacing: 0.08em;
    color: colors.$brown-dark;
    font-family: fonts.$ff-headings;
    font-size: 48px;
    font-style: normal;
    font-weight: fonts.$fw-regular;
    line-height: 50px;

    @include bp.lw(m) {
        font-size: 36px;
        line-height: 38px;
    }
}

@mixin h2() {
    word-spacing: 0.11em;
    letter-spacing: 0.08em;
    color: colors.$brown-dark;
    font-family: fonts.$ff-headings;
    font-size: 30px;
    font-style: normal;
    font-weight: fonts.$fw-regular;
    line-height: 32px;

    @include bp.lw(m) {
        font-size: 24px;
        line-height: 26px;
    }
}

@mixin h3() {
    word-spacing: 0.11em;
    letter-spacing: 0.08em;
    color: colors.$brown-dark;
    font-family: fonts.$ff-headings;
    font-size: 18px;
    font-style: normal;
    font-weight: fonts.$fw-regular;
    line-height: 20px;

    @include bp.lw(m) {
        font-size: 16px;
        line-height: 18px;
    }
}

// Titles
@mixin t1() {
    word-spacing: 0.11em;
    letter-spacing: 0.08em;
    color: colors.$brown-dark;
    font-family: fonts.$ff-body-text;
    font-size: 20px;
    font-style: normal;
    font-weight: fonts.$fw-bold;
    line-height: 22px;

    @include bp.lw(m) {
        font-size: 18px;
        line-height: 20px;
    }
}

@mixin t2() {
    word-spacing: 0.11em;
    letter-spacing: 0.08em;
    color: colors.$brown-dark;
    font-family: fonts.$ff-body-text;
    font-size: 18px;
    font-style: normal;
    font-weight: fonts.$fw-bold;
    line-height: 20px;

    @include bp.lw(m) {
        font-size: 16px;
        line-height: 18px;
    }
}

// Body text
@mixin b1() {
    word-spacing: 0.11em;
    letter-spacing: 0.08em;
    color: colors.$brown-dark;
    font-family: fonts.$ff-body-text;
    font-size: 20px;
    font-style: normal;
    font-weight: fonts.$fw-regular;
    line-height: 30px;

    @include bp.lw(m) {
        font-size: 18px;
        line-height: 20px;
    }
}

@mixin b2() {
    word-spacing: 0.11em;
    letter-spacing: 0.08em;
    color: colors.$brown-dark;
    font-family: fonts.$ff-body-text;
    font-size: 16px;
    font-style: normal;
    font-weight: fonts.$fw-regular;
    line-height: 22px;

    @include bp.lw(m) {
        font-size: 14px;
        line-height: 20px;
    }
}

@mixin b3() {
    word-spacing: 0.11em;
    letter-spacing: 0.08em;
    color: colors.$brown-dark;
    font-family: fonts.$ff-body-text;
    font-size: 12px;
    font-style: normal;
    font-weight: fonts.$fw-regular;
    line-height: 18px;
}
