@use 'sass:map';
@use 'sass:list';
@use '@base-styles-variables/bp';
@use '@base-styles-variables/application' as app;

@mixin xs {
    @media screen and (map.get(bp.$bp-min-width, xs) <= width < map.get(bp.$bp-min-width, s)) {
        @content;
    }
}

@mixin s {
    @media screen and (map.get(bp.$bp-min-width, s) <= width < map.get(bp.$bp-min-width, m)) {
        @content;
    }
}

@mixin m {
    @media screen and (map.get(bp.$bp-min-width, m) <= width < map.get(bp.$bp-min-width, l)) {
        @content;
    }
}

@mixin l {
    @media screen and (map.get(bp.$bp-min-width, l) <= width < map.get(bp.$bp-min-width, xl)) {
        @content;
    }
}

@mixin xl {
    @media screen and (map.get(bp.$bp-min-width, xl) <= width < map.get(bp.$bp-min-width, xxl)) {
        @content;
    }
}

@mixin xxl {
    @media screen and (width >= map.get(bp.$bp-min-width, xxl)) {
        @content;
    }
}

@mixin lw($bp) {
    @if map.has-key(bp.$bp-min-width, $bp) {
        $width: map.get(bp.$bp-min-width, $bp);

        @media screen and (width < #{$width}) {
            @content;
        }
    } @else {
        @warn 'No min breakpoint value for `#{$bp}`. Available values are: `#{map.keys(bp.$bp-min-width)}`.';
    }
}

@mixin lwe($bp) {
    @if map.has-key(bp.$bp-min-width, $bp) {
        $width: map.get(bp.$bp-min-width, $bp);

        @media screen and (width <= #{$width}) {
            @content;
        }
    } @else {
        @warn 'No min breakpoint value for `#{$bp}`. Available values are: `#{map.keys(bp.$bp-min-width)}`.';
    }
}

@mixin gr($bp) {
    @if map.has-key(bp.$bp-min-width, $bp) {
        $width: map.get(bp.$bp-min-width, $bp);

        @media screen and (width > #{$width}) {
            @content;
        }
    } @else {
        @warn 'No min breakpoint value for `#{$bp}`. Available values are: `#{map.keys(bp.$bp-min-width)}`.';
    }
}

@mixin gre($bp) {
    @if map.has-key(bp.$bp-min-width, $bp) {
        $width: map.get(bp.$bp-min-width, $bp);

        @media screen and (width >= #{$width}) {
            @content;
        }
    } @else {
        @warn 'No min breakpoint value for `#{$bp}`. Available values are: `#{map.keys(bp.$bp-min-width)}`.';
    }
}

@mixin gr-app-max-width {
    @media screen and (width > app.$app-max-width) {
        @content;
    }
}

@mixin lw-app-max-width {
    @media screen and (width < app.$app-max-width) {
        @content;
    }
}

@mixin landscape {
    @media (orientation: landscape) {
        @content;
    }
}

@mixin portrait {
    @media (orientation: portrait) {
        @content;
    }
}
