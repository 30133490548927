.nav-items-list {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 260px;
}

.nav-item {
    &:not(:last-child) {
        margin-right: 24px;
    }
}
